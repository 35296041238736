export const BAND_SIZE = 'Band Size';
export const CUP_SIZE = 'Cup Size';
export const SINGLE_SIZE = 'Single Size';
export const COLOUR = 'Colour';
export const EMAIL_SIGNUP = 'Email Signup';
export const PERSONAL_DETAILS = 'Personal Details';
export const REQUEST_MAGAZINE = 'Request Magazine';

/**
 * @param {object} dataLayer
 */
export const setDataLayer = dataLayer => {
  if (typeof window !== 'undefined' && window.setDataLayer) {
    window.setDataLayer({ ...dataLayer });
  }
};

/**
 * @param {object} product
 * @param {string} code
 * @param {number} quantity
 * @returns {object}
 */
export const formatProductForDataLayer = (product, code, quantity) => ({
  name: `${product.styleName} ${product.colour}`,
  id: code,
  price: product.price,
  brand: product.brandName,
  category: product.category,
  colour: product.colour,
  quantity: quantity || product.quantity,
  styleName: product.styleName,
  styleColourCode: product.styleColourCode,
  size: product.size.size,
});

/**
 * @returns {{type}}
 */
export const dataLayerRequestMagazine = () =>
  setDataLayer({ event: 'requestMagazine' });

/**
 * @param {string} type
 * @param {object} product
 * @param {string} currency
 * @param {object} bag
 * @returns {{type}}
 */
export const dataLayerBagChange = (type, product, currency, bag) =>
  setDataLayer({
    event: 'bagChange',
    bagChangeType: type,
    product,
    currency,
    bag,
  });

/**
 * @param {object} item
 * @param {string} currency
 */
export const dataLayerGA4AddToCart = (item, currency) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'add_to_cart',
    ecommerce: {
      currency: currency,
      value: item.price,
      items: [
        {
          item_id: item.styleCode,
          item_style_id: item.styleColourCode,
          item_variant_id: item.skuCode,
          item_name: item.styleName,
          item_style_name: `${item.styleName} ${item.colour}`,
          item_variant: `${item.styleName} ${item.colour} ${item.size.size}`,
          item_size: item.size.size,
          item_size_back: item.size.back,
          item_size_cup: item.size.cup,
          item_brand: item.brandName,
          item_category: item.category,
          price: item.price,
          discount: item.originalPrice - item.price,
          quantity: item.quantity,
        },
      ],
    },
  });
};

/**
 * @param {object} bag
 * @param {string} currency
 */
export const dataLayerGA4Checkout = (bag, currency) => {
  setDataLayerForEventType(bag, 'begin_checkout', currency);
};

/**
 * @param {string} styleCode
 * @param {string} styleName
 * @param {string} collection
 * @param {object} selectedStyleColour
 * @param {string} pathname
 */
export const dataLayerGA4SelectItem = (
  styleCode,
  styleName,
  collection,
  selectedStyleColour,
  pathname
) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'select_item',
    ecommerce: {
      item_list_id: pathname,
      item_list_name: collection,
      item_list_type: pathname && pathname.split('/')[1],
      items: [
        {
          item_id: styleCode,
          item_style_id: selectedStyleColour.styleColourCode,
          item_name: styleName,
          item_style_name: `${styleName} ${selectedStyleColour.name}`,
          item_category: collection,
          price: selectedStyleColour.pricing.price,
          discount:
            selectedStyleColour.pricing.originalPrice -
            selectedStyleColour.pricing.price,
        },
      ],
    },
  });
};

/**
 * @param {string} currency
 * @param {object} bag
 */
export const dataLayerGA4AddShippingInfo = (currency, bag) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'add_shipping_info',
    ecommerce: {
      currency: currency,
      value: bag.subTotal,
      shipping_tier: bag.delivery.method,
      items: bag.items.map(item => ({
        item_id: item.styleCode,
        item_style_id: item.styleColourCode,
        item_variant_id: item.code,
        item_name: item.styleName,
        item_style_name: `${item.styleName} ${item.colour}`,
        item_variant: `${item.styleName} ${item.colour} ${item.size?.size}`,
        item_size: item.size?.size,
        item_size_back: item.size?.back,
        item_size_cup: item.size?.cup,
        item_brand: item.brandName,
        item_category: item.category,
        price: item.price,
        discount: item.originalPrice - item.price,
        quantity: item.quantity,
      })),
    },
  });
};

/**
 * @param {string} currency
 * @param {string} paymentType
 * @param {object} bag
 */
export const dataLayerGA4AddPaymentInfo = (currency, paymentType, bag) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'add_payment_info',
    ecommerce: {
      currency: currency,
      value: bag.subTotal,
      payment_type: paymentType,
      items: bag.items.map(item => ({
        item_id: item.styleCode,
        item_style_id: item.styleColourCode,
        item_variant_id: item.code,
        item_name: item.styleName,
        item_style_name: `${item.styleName} ${item.colour}`,
        item_variant: `${item.styleName} ${item.colour} ${item.size?.size}`,
        item_size: item.size?.size,
        item_size_back: item.size?.back,
        item_size_cup: item.size?.cup,
        item_brand: item.brandName,
        item_category: item.category,
        price: item.price,
        discount: item.originalPrice - item.price,
        quantity: item.quantity,
      })),
    },
  });
};

/**
 * @param {string} currency
 * @param {object} bag
 */
export const dataLayerGA4ViewCart = (currency, bag) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'view_cart',
    ecommerce: {
      currency: currency,
      value: bag.subTotal,
      items: bag.items.map(item => ({
        item_id: item.styleCode,
        item_style_id: item.styleColourCode,
        item_variant_id: item.code,
        item_name: item.styleName,
        item_style_name: `${item.styleName} ${item.colour}`,
        item_variant: `${item.styleName} ${item.colour} ${item.size?.size}`,
        item_size: item.size?.size,
        item_size_back: item.size?.back,
        item_size_cup: item.size?.cup,
        item_brand: item.brandName,
        item_category: item.category,
        price: item.price,
        discount: item.originalPrice - item.price,
        quantity: item.quantity,
      })),
    },
  });
};

/**
 * @param {string} locale
 * @param {string} currency
 * @param {object} bag
 * @param {string} orderId
 */
export const dataLayerGA4Purchase = (locale, currency, bag, orderId) => {
  let taxAmount;
  if (locale === 'en-GB') {
    taxAmount = bag.subTotal * bag.ukTaxRate;
  } else {
    taxAmount = bag.usTaxAmount;
  }
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'purchase',
    ecommerce: {
      transaction_id: orderId,
      value: bag.subTotal,
      currency: currency,
      tax: parseFloat(taxAmount.toFixed(2)),
      shipping: bag.delivery.postage,
      coupon: '',
      affiliation: 'Online Store',
      items: bag.items.map(item => ({
        item_id: item.styleCode,
        item_style_id: item.styleColourCode,
        item_variant_id: item.code,
        item_name: item.styleName,
        item_style_name: `${item.styleName} ${item.colour}`,
        item_variant: `${item.styleName} ${item.colour} ${item.size.size}`,
        item_size: item.size.size,
        item_size_back: item.size.back,
        item_size_cup: item.size.cup,
        item_brand: item.brandName,
        item_category: item.category,
        price: item.price,
        discount: item.originalPrice - item.price,
        quantity: item.quantity,
      })),
    },
  });
};

/**
 * @param {object} order
 */
export const dataLayerGA4Refund = order => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'refund',
    ecommerce: {
      currency: order.orderCurrency,
      transaction_id: order.orderId,
      value: order.orderValue,
      items: order.awaitingDespatch.map(item => ({
        item_id: item.styleCode,
        item_style_id: item.styleColourCode,
        item_variant_id: `${item.styleColourCode}${item.size.size}`,
        item_name: item.styleName,
        item_style_name: `${item.styleName} ${item.colour}`,
        item_variant: `${item.styleName} ${item.colour} ${item.size.size}`,
        item_size: item.size.size,
        item_size_back: item.size.back,
        item_size_cup: item.size.cup,
        item_brand: item.brandName,
        price: item.lineGross,
        discount: item.lineDiscount,
        quantity: item.ordered,
      })),
    },
  });
};

/**
 * @param {object} item
 * @param {string} currency
 */
export const dataLayerGA4RemoveFromCart = (item, currency) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'remove_from_cart',
    ecommerce: {
      currency: currency,
      value: item.price,
      items: [
        {
          item_id: item.styleCode,
          item_style_id: item.styleColourCode,
          item_variant_id: item.skuCode,
          item_name: item.styleName,
          item_style_name: `${item.styleName} ${item.colour}`,
          item_variant: `${item.styleName} ${item.colour} ${item.size.size}`,
          item_size: item.size.size,
          item_size_back: item.size.back,
          item_size_cup: item.size.cup,
          item_brand: item.brandName,
          item_category: item.category,
          price: item.price,
          discount: item.originalPrice - item.price,
          quantity: item.quantity,
        },
      ],
    },
  });
};

/**
 * @param {object} items
 * @param {object} collection
 * @param {string} pathname
 *
 */
export const dataLayerGA4ViewItemList = (items, collection, pathname) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'view_item_list',
    ecommerce: {
      item_list_id: pathname,
      item_list_name: collection,
      item_list_type: pathname.split('/')[1],
      items: items.map(item => ({
        item_id: item.styleCode,
        item_name: item.styleName,
        item_category: collection,
      })),
    },
  });
};

/**
 * @param {object} product
 * @param {string} currency
 */
export const dataLayerGA4ViewItem = (product, currency) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'view_item',
    ecommerce: {
      currency: currency,
      value: product.prices.price,
      items: [
        {
          item_id: product.styleCode,
          item_style_id: product.styleColourCode,
          item_name: product.styleName,
          item_style_name: `${product.styleName} ${product.colour.colour}`,
          item_brand: product.brand,
          item_category: product.category,
          price: product.prices.price,
          discount: product.prices.originalPrice - product.prices.price,
        },
      ],
    },
  });
};

/**
 * @param {object} product
 * @returns {{type}}
 */
export const dataLayerSetProduct = product =>
  setDataLayer({ event: 'setProduct', product });

/**
 * @param {string} action
 * @param {string} name
 * @returns {{type}}
 */
export const dataLayerEditorialModal = (action, name) =>
  setDataLayer({
    event: 'editorialModalAction',
    editorialModalActionType: `Popup: ${name} - ${
      action === 'open' ? 'Display' : 'Dismiss'
    }`,
  });

/**
 * @param {object} bag
 * @param {string} eventType
 * @param {string} currency
 */
function setDataLayerForEventType(bag, eventType, currency) {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: eventType,
    ecommerce: {
      currency: currency,
      value: bag.subTotal,
      items: bag.items.map(item => ({
        item_id: item.styleCode,
        item_style_id: item.styleColourCode,
        item_variant_id: item.code,
        item_name: item.styleName,
        item_style_name: `${item.styleName} ${item.colour}`,
        item_variant: `${item.styleName} ${item.colour} ${item.size.size}`,
        item_size: item.size.size,
        item_size_back: item.size.back,
        item_size_cup: item.size.cup,
        item_brand: item.brandName,
        item_category: item.category,
        price: item.price,
        discount: item.originalPrice - item.price,
        quantity: item.quantity,
      })),
    },
  });
}

/**
 * @param {string} stageNumber
 */
export const dataLayerGA4SizeQuizStageNumber = stageNumber => {
  setDataLayer({
    event: 'bra_fit_finder_stage_number',
    stage: `User is on stage number: ${stageNumber}`,
  });
};

/**
 * @param {string} stageNumber
 */
export const dataLayerGA4ChatToFitter = stageNumber => {
  setDataLayer({
    event: 'bra_fit_finder_chat_to_fitter',
    stage: `Chat to fitter CTA - stage:${stageNumber}`,
  });
};

/**
 * @param {string} stageNumber
 */
export const dataLayerGA4HyperlinksInText = stageNumber => {
  setDataLayer({
    event: 'bra_fit_finder_hyperlinks_in_text',
    stage: `Hyperlinks in text - stage:${stageNumber}`,
  });
};

/**
 * @param {string} stageNumber
 * @param {string} backSize
 * @param {string} cupSize
 */
export const dataLayerGA4UserSize = (stageNumber, backSize, cupSize) => {
  if (backSize && cupSize) {
    if (stageNumber === 1) {
      setDataLayer({
        event: 'bra_fit_finder_user_size',
        size: `User has selected - back size:${backSize}, cup size:${cupSize}`,
      });
    } else {
      setDataLayer({
        event: 'bra_fit_finder_user_size',
        size: `User's recommended size is - back size:${backSize}, cup size:${cupSize}`,
      });
    }
  }
};

/**
 * @param {string} stageNumber
 * @param {string} elapsedTime
 */
export const dataLayerGA4TimeOnQuizStage = (stageNumber, elapsedTime) => {
  const stage = stageNumber + 1;

  setDataLayer({
    event: 'bra_fit_finder_time_spent',
    time: `Time spent on stage ${stage}: ${elapsedTime} seconds`,
  });
};

/**
 * @param {string} styleName
 * @param {string} styleCode
 */
export const dataLayerGA4SelectedRailItem = (styleName, styleCode) => {
  setDataLayer({
    event: 'editorial_content',
    name: styleName,
    sku: styleCode,
  });
};

/**
 * @param {string} searchPhraseUrl
 */
export const dataLayerSliCollectionsSearch = searchPhraseUrl => {
  setDataLayer({
    event: 'collectionsSearch',
    searchPhraseUrl: searchPhraseUrl,
  });
};

/**
 * @param {string} selectedNavItem
 */
export const dataLayerNavItemSelected = selectedNavItem => {
  setDataLayer({
    event: 'nav_item_selected',
    selectedNavItem: selectedNavItem,
  });
};

/**
 * @param {string} userId
 */
export const dataLayerSignUp = userId => {
  setDataLayer({ event: 'sign_up', user_id: userId });
};

/**
 * @param {string} userId
 */
export const dataLayerLogin = userId => {
  setDataLayer({ event: 'login', user_id: userId });
};

/**
 * @param {string} formId
 * @param {string} formName
 * @param {string} formDestination
 * @param {string} formSubmitText
 * @param {string} formType
 */
export const dataLayerFormSubmit = (
  formId,
  formName,
  formDestination,
  formSubmitText,
  formType
) => {
  setDataLayer({
    event: 'form_submit',
    form_id: formId,
    form_name: formName,
    form_destination: formDestination,
    form_submit_text: formSubmitText,
    form_type: formType,
  });
};

/**
 * @param {string} locale
 */
export const dataLayerLocaleChange = locale => {
  setDataLayer({ event: 'locale_change', locale_to: locale });
};

/**
 * @param {string} type
 * @param {string} selected
 * @param {object} item
 */
export const dataLayerSelectItemOption = (type, selected, item) => {
  setDataLayer({
    event: 'select_item_option',
    item_option_type: type,
    item_option_selected: selected,
    items: [
      {
        item_id: item.styleCode,
        item_style_id: item.styleColourCode,
        item_name: item.styleName,
        item_style_name: `${item.styleName} ${item.colour.colour}`,
        item_brand: item.brand,
        item_category: item.category,
        price: item.prices.price,
        discount: item.prices.originalPrice - item.prices.price,
        quantity: 1,
      },
    ],
  });
};

/**
 * @param {string} currency
 * @param {object} product
 */
export const dataLayerAddToWishList = (currency, product) => {
  setDataLayer({ ecommerce: null });
  setDataLayer({
    event: 'add_to_wishlist',
    ecommerce: {
      currency: currency,
      value: product.prices.price,
      items: [
        {
          item_id: product.styleCode,
          item_style_id: product.styleColourCode,
          item_name: product.styleName,
          item_style_name: `${product.styleName} ${product.colour.colour}`,
          item_brand: product.brand,
          item_category: product.category,
          price: product.prices.price,
          discount: product.prices.originalPrice - product.prices.price,
        },
      ],
    },
  });
};
