import PropTypes from 'prop-types';
import React from 'react';

const Swatch = ({ colour, swatchPatternImage, isUnavailable }) => (
  <span
    className="c-swatch"
    style={
      swatchPatternImage?.alt !== 'Awaiting photo' && swatchPatternImage?.src
        ? {
            backgroundImage: `
            ${
              isUnavailable
                ? "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M1 0 L0 1 L99 100 L100 99' fill='white'/></svg>\"),"
                : ''
            }
            url(${swatchPatternImage?.src})
          `,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }
        : { backgroundColor: colour }
    }
  />
);

Swatch.propTypes = {
  colour: PropTypes.string.isRequired,
  swatchPatternImage: PropTypes.shape({
    alt: PropTypes.string,
    src: PropTypes.string,
  }),
  isUnavailable: PropTypes.bool,
};

export default Swatch;
