import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { defineMessages, intlShape, injectIntl } from 'react-intl';
import flatten from 'lodash/flatten';
import Icon from 'brastrap/common/icon/Icon';
import Modal from 'brastrap/common/modal/Modal';
import Options from './FieldBrasizeOptions';
import { dataLayerSelectItemOption, BAND_SIZE, CUP_SIZE } from '../../../../shared/utils/data-layer';

const CLASS_NAME = 'c-field-brasize';

const messages = defineMessages({
  'dress-button': {
    id: 'field-brasize-dress.button-label',
    defaultMessage: 'Select Size',
  },
  'size-button': {
    id: 'field-brasize-size.button-label',
    defaultMessage: 'Select Size',
  },
  'back-button': {
    id: 'field-brasize-back.button-label',
    defaultMessage: 'Select Size',
  },
  'dress-modal-title': {
    id: 'field-brasize-dress.modal-title',
    defaultMessage: 'Select Size',
  },
  'size-modal-title': {
    id: 'field-brasize-size.modal-title',
    defaultMessage: 'Select Size',
  },
  'back-modal-title': {
    id: 'field-brasize-back.modal-title',
    defaultMessage: 'Select Size',
  },
  'dress-value': {
    id: 'field-brasize-dress.label-value',
    defaultMessage: '{label}',
  },
  'size-value': {
    id: 'field-brasize-size.label-value',
    defaultMessage: '{label}',
  },
  'back-value': {
    id: 'field-brasize-back.label-value',
    defaultMessage: '{label}',
  },
  'ff-cup-recommendation': {
    id: 'field-brasize-back.ff-cup-recommendation',
    defaultMessage: `If you’re having trouble deciding between an F and G cup, we recommend trying an FF cup.`,
  },
});

/**
 * @param {Function} formatMessage
 * @param {Function} onClick
 * @param {boolean} respectStockLevels
 * @param {Object} sizes
 * @param {Object} selectedSku
 * @param {string} value
 * @returns {XML}
 * @constructor
 */
const Button = ({
  intl: { formatMessage },
  onClick,
  respectStockLevels,
  sizes,
  selectedSku,
  value,
  sizeType,
}) => {
  let label = '';

  if (!value && !selectedSku) {
    label = formatMessage(messages[`${sizeType}-button`]);
  } else if (selectedSku) {
    label = respectStockLevels
      ? formatMessage(messages[`${sizeType}-value`], selectedSku)
      : selectedSku.label;
  } else {
    const skus = sizes.variants.map(variant => variant.sizes.variants);
    const sku = flatten(skus).find(s => s.skuCode === value);
    label = sku ? sku.label : formatMessage(messages[`${sizeType}-button`]);
  }

  return (
    <button className="c-field-brasize__button" onClick={onClick}>
      {label}
    </button>
  );
};

Button.propTypes = {
  intl: intlShape,
  onClick: PropTypes.func,
  respectStockLevels: PropTypes.bool,
  sizes: PropTypes.object,
  selectedSku: PropTypes.object,
  value: PropTypes.string,
  sizeType: PropTypes.string,
};

/**
 * @param {Object} props
 * @return {XML}
 * @constructor
 */
const FieldBraSize = props => {
  const {
    intl: { formatMessage },
    modifiers,
    onChange,
    onModalClose,
    onModalOpen,
    modalId,
    isOpen,
    respectStockLevels,
    sizes,
    sizeType,
    locale,
    mapSelectedUKCupSizeToUS,
    useSingleDropdown,
    selectedSku,
    styleCode,
    selectedSkuRef,
    product
  } = props;

  const [selectedBand, setSelectedBand] = useState(null);
  const [availableVariants, setAvailableVariants] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState('DEFAULT');

  const optionsProps = {
    onChange: onChange,
    respectStockLevels: respectStockLevels,
    variants: sizes.variants,
    styleCode: styleCode,
    selectedSkuRef: selectedSkuRef,
    product: product
  };

  if (modalId) {
    optionsProps.idPrefix = `${modalId}-`;
  }

  useEffect(() => {
    if (typeof window !== 'undefined' && (selectedVariant === "DEFAULT" || !selectedBand) && selectedSkuRef && selectedSkuRef !== undefined) {
      const stringifiedObj = sessionStorage.getItem('selectedSkuDetails');
      const selectedSkuDetails = JSON.parse(stringifiedObj);

      const matchingValue = selectedSkuDetails && sizes.variants && sizes.variants.find(variant => variant.value === selectedSkuDetails.bandSize);

      if (selectedSkuDetails && selectedSkuDetails.styleCode === styleCode && matchingValue && matchingValue !== undefined) {
        let arrayOfVariants = [];
        sizes &&
        sizes.variants
          .find(variant => variant.value === selectedSkuDetails.bandSize)
          .sizes.variants.forEach(variant => {
            arrayOfVariants.push(variant);
          });

        setSelectedBand(selectedSkuDetails.bandSize);
        setSelectedVariant(arrayOfVariants.find(variant => variant.value === selectedSkuDetails.cupSize));
      } else {
        selectedSkuRef.current = null;
      }
    }
  }, []);

  /* eslint-disable */
  useEffect(() => {
    if (selectedBand) {
      onChange(null);
      let arrayOfVariants = [];
      sizes &&
        sizes.variants
          .find(variant => variant.value === selectedBand)
          .sizes.variants.forEach(variant => {
            arrayOfVariants.push(variant);
          });
      setAvailableVariants([...new Set(arrayOfVariants.sort())]);
    }
  }, [selectedBand]);
  /* eslint-enable */

  useEffect(() => {
    if (selectedVariant && selectedVariant !== 'DEFAULT') {
      onChange(selectedVariant);
    } else {
      sessionStorage.removeItem('selectedSkuDetails');
    }
  }, [selectedVariant]);

  return (
    <>
      {locale === 'en-GB' || useSingleDropdown ? (
        <div
          className={`${CLASS_NAME} ${modifiers
            .map(modifier => `${CLASS_NAME}--${modifier}`)
            .join(' ')}`}
        >
          <div className={`${CLASS_NAME}__selectorContainer`}>
            {!useSingleDropdown && <div>BAND &amp; CUP SIZE</div>}
            <Button {...props} onClick={() => onModalOpen(modalId)} />
          </div>
          <Modal
            actions={{
              dismiss: {
                icon: {
                  icon: 'dismiss',
                  label: 'Close',
                  labelled: 'after',
                },
                onClick: onModalClose,
              },
            }}
            modalId={modalId}
            isOpen={isOpen}
            modifiers={['field-brasize']}
            title={formatMessage(messages[`${sizeType}-modal-title`])}
          >
            <Options {...optionsProps} />
          </Modal>
        </div>
      ) : (
        <div className={`${CLASS_NAME}__usOnlyContainer`}>
          <div className={`${CLASS_NAME}__usOnlySelectContainer`}>
            <label className={`${CLASS_NAME}__usOnlySelectLabel`}>
              BAND SIZE
              <select
                onChange={e => {
                  e.preventDefault();
                  setSelectedBand(e.currentTarget.value);
                  dataLayerSelectItemOption(BAND_SIZE, e.currentTarget.value, product);
                  setSelectedVariant('DEFAULT');
                  e.stopPropagation();
                }}
                value={selectedBand}
                className="c-field-select"
                defaultValue={'DEFAULT'}
              >
                <option value={'DEFAULT'} disabled>
                  Select band...
                </option>
                {sizes &&
                  sizes.variants.map((variant, key) => {
                    return (
                      <option value={variant.value} key={key}>
                        {variant.value}
                      </option>
                    );
                  })}
              </select>
            </label>
          </div>

          <div className={`${CLASS_NAME}__usOnlySelectContainer`}>
            <label className={`${CLASS_NAME}__usOnlySelectLabel`}>
              CUP SIZE
              <select
                onChange={e => {
                  e.preventDefault();
                  setSelectedVariant(JSON.parse(e.currentTarget.value));
                  dataLayerSelectItemOption(CUP_SIZE, e.currentTarget.value, product);
                  e.stopPropagation();
                }}
                className="c-field-select"
                defaultValue={'DEFAULT'}
                value={
                  selectedVariant === 'DEFAULT'
                    ? 'DEFAULT'
                    : JSON.stringify(selectedVariant)
                }
              >
                <option value={'DEFAULT'} disabled>
                  Select cup...
                </option>
                {availableVariants &&
                  availableVariants.map((variant, key) => {
                    return (
                      <option value={JSON.stringify(variant)} key={key}>{`${
                        variant.value
                      } ${mapSelectedUKCupSizeToUS(variant.value)}`}</option>
                    );
                  })}
              </select>
            </label>
          </div>
        </div>
      )}

      {(selectedSku?.value === 'F' || selectedSku?.value === 'G') && (
        <div className="size-selector-widget__selectedFFCupSizeContainer size-selector-widget__selectedFFCupSizeContainer--mobile">
          <Icon icon="information" />
          <span className="size-selector-widget__selectedFFCupSize">
            {formatMessage(messages['ff-cup-recommendation'])}
          </span>
        </div>
      )}
    </>
  );
};

FieldBraSize.propTypes = {
  intl: intlShape,
  respectStockLevels: PropTypes.bool.isRequired,
  modalId: PropTypes.string,
  isOpen: PropTypes.bool,
  sizeType: PropTypes.string,
  selectedSku: PropTypes.object,
  modifiers: PropTypes.array,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  sizes: PropTypes.object.isRequired,
  onModalOpen: PropTypes.func.isRequired,
  onModalClose: PropTypes.func.isRequired,
  locale: PropTypes.string,
  mapSelectedUKCupSizeToUS: PropTypes.func,
  useSingleDropdown: PropTypes.bool,
  styleCode: PropTypes.string,
  selectedSkuRef: PropTypes.object,
  product: PropTypes.object
};

FieldBraSize.defaultProps = {
  respectStockLevels: true,
  modifiers: [],
  layer: {},
  onChange: () => {},
  onModalOpen: () => {},
  onModalClose: () => {},
  sizeType: 'size',
};

export default injectIntl(FieldBraSize);
