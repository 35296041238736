import PropTypes from 'prop-types';
import React from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import { BAND_SIZE, CUP_SIZE, dataLayerSelectItemOption } from '../../../../shared/utils/data-layer';

/**
 * Renders a list of options in the bra size picker. Also handles rendering
 * a sub-list, if one is available. Props:
 *
 * @param {Boolean} isChild whether this list is sub-list
 * @param {Function} onChange callback when a final value is selected
 * @param {Boolean} respectStockLevels whether to allow out-of-stock items to be selected
 * @param {Array} variants the options that can be selected
 * @param {string} styleCode the stylecode
 */
class FieldBrasizeOptions extends React.Component {
  /**
   * @param {Object} props
   */
  constructor(props) {
    super(props);
    this.state = {
      selectedParent: null,
    };
    this.messages = defineMessages({
      displayCurvy: {
        id: 'field-brasize-option.displayCurvy-label',
        defaultMessage: '{label}',
      },
      cup: { id: 'field-brasize-option.cup-label', defaultMessage: '{label}' },
    });
  }

  /**
*/
  componentDidMount() {
    if (typeof window !== 'undefined' && this.props.selectedSkuRef && this.props.selectedSkuRef !== undefined) {
      const stringifiedObj = sessionStorage.getItem('selectedSkuDetails');
      const selectedSkuDetails = JSON.parse(stringifiedObj);

      const matchingValue = selectedSkuDetails && this.props.variants && this.props.variants.find(variant => variant.value === selectedSkuDetails.value);

      if (selectedSkuDetails && selectedSkuDetails.styleCode === this.props.styleCode && matchingValue) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({selectedParent: selectedSkuDetails.value});
      } else {
        this.props.selectedSkuRef.current = null;
      }
    }
  }

  /**
   * Toggles whether to show the sub-list for a given parent list value. If the
   * currently-selected list value is passed, toggle off the sub-list.
   * @param {string} value
   */
  handleParentSelect(value) {
    this.setState({
      selectedParent: this.state.selectedParent === value ? null : value,
    });
    dataLayerSelectItemOption(BAND_SIZE, value, this.props.product);
  }

  /**
   * @return {JSX.Element}
   */
  render() {
    const {
      idPrefix,
      isChild,
      isVisible,
      onChange,
      respectStockLevels,
      variants,
    } = this.props;
    const containerClass = isChild ? 'child-container' : 'parent-container';
    const classes = {
      container: `c-field-brasize__${containerClass}${isVisible ? '' : ' c-field-brasize--hidden'
        }`,
      option: `c-field-brasize__${isChild ? 'child' : 'parent'}`,
    };

    const labelPattern = /([0-9]{1,2}\s)/g;
    const labelsWrapOnMobile = variants
      .filter(variant => variant.label)
      .some(sku => {
        const label = !labelPattern.test(sku.label)
          ? sku.value
          : sku.label.replace(labelPattern, '');
        return label.length >= 5;
      });

    return (
      <ol className={classes.container}>
        {variants &&
          variants.length &&
          variants.map((variantOrSku, key) => {
            if (variantOrSku.sizes && variantOrSku.sizes.value) {
              const labelValue = variantOrSku.sizes.value || 'cup';
              // There are more variants
              return (
                <li
                  key={key}
                  onClick={() => this.handleParentSelect(variantOrSku.value)}
                  data-value={variantOrSku.value}
                  tabIndex="0"
                  className={
                    variantOrSku.value === this.state.selectedParent
                      ? `${classes.option} ${classes.option}--selected`
                      : classes.option
                  }
                >
                  <p className="c-field-brasize__label">
                    <FormattedMessage
                      {...this.messages[labelValue]}
                      values={{ label: variantOrSku.value }}
                    />
                  </p>
                  <FieldBrasizeOptions
                    idPrefix={idPrefix}
                    isChild
                    isVisible={this.state.selectedParent === variantOrSku.value}
                    onChange={onChange}
                    respectStockLevels={respectStockLevels}
                    variants={variantOrSku.sizes.variants}
                  />
                </li>
              );
            }

            // There are no more variants. This is the SKU
            const sku = variantOrSku;

            const inputProps = {
              className: 'u-hidden',
              id: idPrefix ? `${idPrefix}${sku.skuCode}` : sku.skuCode,
              name: 'size',
              type: 'radio',
              value: sku.skuCode,
              onClick: () => {
                // Only trigger the onChange callback if:
                // - We care about stock levels AND the stock is available, or
                // - We don't care if the product is in stock or not.
                if (!respectStockLevels || sku.available) {
                  onChange(sku);
                }
              },
            };

            const liProps = {
              className: classes.option,
              'data-value': sku.value,
              key,
            };

            // Make the input disabled if we care about stock levels and the sku is not available :)
            if (respectStockLevels && !sku.available) {
              inputProps.disabled = true;
            }

            const label = !labelPattern.test(sku.label)
              ? sku.value
              : sku.label.replace(labelPattern, '');
            const LABEL_STYLE_PREFIX = 'c-field-brasize__label';
            let labelClass = LABEL_STYLE_PREFIX;
            if (labelsWrapOnMobile) {
              labelClass += ` ${LABEL_STYLE_PREFIX}--wraps`;
            }

            // The event handler on the inner <li> is there to prevent the parent <li> click handler
            // firing when we don't want it to.
            return (
              <li onClick={ev => { ev.stopPropagation(); dataLayerSelectItemOption(CUP_SIZE, liProps['data-value'], this.props.product)}} {...liProps}>
                <input {...inputProps} />
                <label htmlFor={inputProps.id} className={labelClass}>
                  <p className="c-field-brasize__label-item--value">{label}</p>
                  {sku.stockStatus && respectStockLevels && (
                    <p className="c-field-brasize__label-item--note">
                      <small className="c-field-brasize__note">
                        {sku.stockStatus}
                      </small>
                    </p>
                  )}
                </label>
              </li>
            );
          })}
      </ol>
    );
  }
}

FieldBrasizeOptions.propTypes = {
  idPrefix: PropTypes.string,
  isChild: PropTypes.bool,
  isVisible: PropTypes.bool,
  onChange: PropTypes.func,
  respectStockLevels: PropTypes.bool,
  variants: PropTypes.array,
  selected: PropTypes.string, // Exists for unit testing
  styleCode: PropTypes.string,
  selectedSkuRef: PropTypes.object,
  product: PropTypes.object
};

FieldBrasizeOptions.defaultProps = {
  isChild: false,
  isVisible: true,
  respectStockLevels: true,
};

export default injectIntl(FieldBrasizeOptions);
