import PropTypes from 'prop-types';
import React from 'react';
import { FormattedNumber, FormattedMessage } from 'react-intl';
import FieldSelectNumber from 'brastrap/common/field-select-number/FieldSelectNumber';
import Sku from 'brastrap/transactional/sku/Sku';
import SaveItemButton from 'brastrap/promotional/save-item-button/SaveItemButton';
import Link from 'brastrap/containers/link/LinkContainer';
import { applyModifiers } from '../../utils';
import {
  dataLayerAddToWishList,
  dataLayerGA4RemoveFromCart,
} from '../../../../shared/utils/data-layer';

const STYLE_PREFIX = 'c-bag__item';

const BagItem = ({
  currency,
  item,
  isEditable,
  modifiers,
  onChange,
  onDelete,
  disableClickableThumbnails,
  displaySaveItemButton,
  onSaveItemPress,
  savedItemsMap,
}) => {
  const code = item.code;
  const canChangeQuantity = item.skuCode !== 'GV01';
  const trProps = {
    className: applyModifiers(STYLE_PREFIX, modifiers),
    'data-sku': code,
  };

  const hasProductPage = canChangeQuantity && !item.isAccessory;

  /**
  handles on click event
 */
  function handleOnClick() {
    dataLayerGA4RemoveFromCart(item, currency);
    onDelete(code);
  }

  return (
    <tr {...trProps}>
      <td className="c-bag__sku">
        {hasProductPage && !disableClickableThumbnails ? (
          <Link href={item.url}>
            <Sku
              item={item}
              showBrandName={isEditable}
              upsell={item.isEditable}
            />
          </Link>
        ) : (
          <Sku
            item={item}
            showBrandName={isEditable}
            upsell={item.isEditable}
          />
        )}
        {canChangeQuantity && displaySaveItemButton && (
          <SaveItemButton
            isItemSaved={savedItemsMap[item.code]}
            isSizeSelected
            onClick={() => {
              dataLayerAddToWishList(currency, {
                prices: {
                  price: item.price,
                  originalPrice: item.originalPrice,
                },
                styleCode: item.styleCode,
                styleColourCode: item.styleColourCode,
                styleName: item.name,
                colour: {
                  colour: item.colour,
                },
                brand: item.brand,
              });
              // Confirmed by marketing, 12/12/2017: we will only show the 'Save Item'
              // button for logged in users visiting the bag page
              onSaveItemPress({
                styleColourCode: item.styleColourCode,
                skuCode: item.code,
                styleCode: item.styleCode,
              });
            }}
          />
        )}
      </td>
      {!isEditable && (
        <td>
          <div className="c-bag__quantity">
            <p>x{item.quantity}</p>
          </div>
        </td>
      )}

      {isEditable && (
        <td>
          {canChangeQuantity && (
            <FieldSelectNumber
              className="c-bag__quantity--select"
              min="1"
              max="10"
              modifiers={['muted']}
              name="select"
              value={item.quantity}
              onChange={evt => onChange(code, evt.currentTarget.value)}
            />
          )}
        </td>
      )}

      {isEditable && (
        <td>
          <button
            className="c-bag__remove"
            onClick={handleOnClick}
            type="button"
            ariaLabel="Remove item from bag"
          >
            <span className="c-icon c-icon--delete">
              <svg
                className="c-icon__glyph"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 200 200"
                role="img"
              >
                <path
                  fillRule="evenodd"
                  d="M84 24H22c-8 0-14 6-14 14s6 14 14 14h156c8 0 14-6
                  14-14s-6-14-14-14h-62V14c0-8-6-14-14-14h-4c-8 0-14 6-14 14v10zM24 64h152v120c0 9-7 16-16
                  16H40c-9 0-16-7-16-16V64zm28 0h96v104c0 2-2 4-4 4H56c-2 0-4-2-4-4V64zm12 0h16v96H64V64zm28
                  0h16v96H92V64zm28 0h16v96h-16V64z"
                />
              </svg>
              <span className="u-hidden">
                <FormattedMessage id="bag.remove" defaultMessage="Remove" />
              </span>
            </span>
          </button>
        </td>
      )}

      <td>
        <p className="c-bag__price">
          <FormattedNumber
            value={item.price * item.quantity}
            currency={currency}
            style="currency"
          />
        </p>
      </td>
    </tr>
  );
};

BagItem.propTypes = {
  currency: PropTypes.string,
  item: PropTypes.shape({
    colour: PropTypes.string,
    brand: PropTypes.string,
    styleColourCode: PropTypes.string,
    code: PropTypes.string,
    id: PropTypes.number,
    images: PropTypes.shape({
      alternative: PropTypes.arrayOf(
        PropTypes.shape({
          alt: PropTypes.string,
          ref: PropTypes.string,
          title: PropTypes.string,
        })
      ),
      main: PropTypes.shape({
        alt: PropTypes.string,
        ref: PropTypes.string,
        title: PropTypes.string,
      }),
      thumbnail: PropTypes.shape({
        alt: PropTypes.string,
        ref: PropTypes.string,
        title: PropTypes.string,
      }),
    }),
    isAccessory: PropTypes.boolean,
    isEditable: PropTypes.boolean,
    name: PropTypes.string,
    originalPrice: PropTypes.number,
    price: PropTypes.number,
    quantity: PropTypes.number,
    size: PropTypes.shape({
      back: PropTypes.string,
      cup: PropTypes.string,
      curvy: PropTypes.string,
      dress: PropTypes.string,
      size: PropTypes.string,
    }),
    skuCode: PropTypes.string,
    styleCode: PropTypes.string,
    url: PropTypes.string,
    colourSwatch: PropTypes.string,
  }),
  displaySaveItemButton: PropTypes.bool,
  isEditable: PropTypes.bool,
  modifiers: PropTypes.array,
  onChange: PropTypes.func,
  onDelete: PropTypes.func,
  onSaveItemPress: PropTypes.func,
  savedItemsMap: PropTypes.object,
  skuCode: PropTypes.string,
  disableClickableThumbnails: PropTypes.bool,
};

BagItem.defaultProps = {
  disableClickableThumbnails: false,
  displaySaveItemButton: false,
  isBagPage: false,
};

export default BagItem;
