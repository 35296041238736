import isEmpty from 'lodash/isEmpty';

import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGOUT_FAILURE,
  IS_EMAIL_REGISTERED_REQUEST,
  IS_EMAIL_REGISTERED_SUCCESS,
  IS_EMAIL_REGISTERED_FAILURE,
  NOT_AUTHORISED,
} from '../constants/auth';

import { CALL_API } from '../constants/api';

import { setGuestEmail } from './checkout';
import { addMessage, clearAllMessages } from './messages';
import { receiveBag } from './bag';
import { startPopoverBagCloseTimer, showBag } from './masthead/header';
import { replace, push } from './app/routing';
import { apiSaveItem, receiveSavedItemsList } from './saved-items';
import { close as closeAddPaymentForm } from './add-payment-option';
import { dataLayerLogin } from '../utils/data-layer';

const LOGIN_MESSAGES_KEY = 'login';

const onRegisteredErrorSetGuestEmail = email => dispatch => {
  dispatch(isEmailRegisteredFailure());
  dispatch(setGuestEmail(email));
};

/**
 * Request login action
 *
 * @param {object} credentials
 * @returns {{type, isFetching: boolean, isAuthenticated: boolean, credentials: *}}
 */
export const loginRequest = credentials => dispatch => {
  const payload = {
    type: LOGIN_REQUEST,
    payload: {
      credentials,
    },
  };

  dispatch(payload);
  dispatch(clearAllMessages(LOGIN_MESSAGES_KEY));
};

/**
 * Action for when the user has been logged in.
 *
 * @param {object} authData containing user and token
 * @returns {*}
 */
export const loginSuccess = authData => dispatch => {
  const { id } = authData.user;

  const action = {
    type: LOGIN_SUCCESS,
    payload: {
      ...authData,
    },
  };
  dataLayerLogin(id);
  dispatch(action);
};

/**
 * Action used only by the login page upon successful login. Allows a browser forward
 * so that the user can access whatever resource was protected by the login prompt.
 *
 * @param {Object} authData authentication credentials
 * @param {String|null} nextPathname the next location to which to send the browser
 * @returns {Object|function(*)} Either a `loginSuccess` action, or a thunk that calls
 * the `loginSuccess` action and then the `forward` action.
 */
export const apiLoginSuccess = (authData, nextPathname = null) => (
  dispatch,
  getState
) => {
  const showItemsAddedToBag = () => {
    if (authData.itemsAddedMessage) {
      dispatch(showBag());
      dispatch(addMessage(authData.itemsAddedMessage, 'bag'));
      dispatch(startPopoverBagCloseTimer());
    }
  };

  dispatch(clearAllMessages('bag'));
  dispatch(receiveBag(authData.bag));
  if (authData.savedItems && Object.keys(authData.savedItems).length) {
    dispatch(receiveSavedItemsList(authData.savedItems));
  }

  /*
   * We need to make sure that, if we have a default payment method, we do not show the open addPaymentMethod form.
   * Not only is it confusing for customers, it creates problems in the build pipeline
   */
  const isAddPaymentDetailsFormOpen = getState().paymentCardDetailsForm.display;

  if (
    authData.defaultPaymentMethod &&
    authData.defaultPaymentMethod.tokenRef &&
    isAddPaymentDetailsFormOpen
  ) {
    dispatch(closeAddPaymentForm());
  }

  if (!nextPathname) {
    showItemsAddedToBag();
    return dispatch(loginSuccess(authData));
  }
  return dispatch(loginSuccess(authData))
    .then(() => {
      dispatch(replace(nextPathname)).then(showItemsAddedToBag);
    })
    .then(() => {
      const pending = getState().savedItems.pendingItem;
      if (!isEmpty(pending)) {
        dispatch(apiSaveItem(pending));
      }
    });
};

/**
 * Action for when the user's login details are not correct
 *
 * @param {object} errorData containing error message
 * @returns {{type, isFetching: boolean, isAuthenticated: boolean, message: {content: *, type: string}}}
 */
const loginFailure = errorData => dispatch => {
  const action = {
    type: LOGIN_FAILURE,
    payload: {
      message: {
        content: errorData.message,
        type: 'warn',
      },
    },
  };

  dispatch(action);
  dispatch(addMessage(errorData.message, LOGIN_MESSAGES_KEY, true));
};

/**
 * Action to request a logout
 *
 * @returns {{type}}
 */
export function logoutRequest() {
  return {
    type: LOGOUT_REQUEST,
  };
}

/**
 * Action for when a user has successfully logged out
 *
 * @returns {{type: LogoutSuccess}}
 */
export function logoutSuccess() {
  return {
    type: LOGOUT_SUCCESS,
  };
}

/**
 * @returns {function(*)} A thunk that dispatches a logout success action and then the `forward` action to '/'
 */
export function logoutThenRedirect() {
  return (dispatch, getState) => {
    const { basePath } = getState().app.config;
    dispatch(logoutSuccess()).then(() => dispatch(push(`${basePath}login`)));
  };
}

/**
 * @returns {{type}}
 */
export function logoutFailure() {
  return {
    type: LOGOUT_FAILURE,
  };
}

/**
 * @returns {{type: string}}
 */
export function isEmailRegisteredRequest() {
  return {
    type: IS_EMAIL_REGISTERED_REQUEST,
  };
}

/**
 * @param {Object} user
 * @returns {{type, payload: *}}
 */
export function isEmailRegisteredSuccess(user) {
  return {
    type: IS_EMAIL_REGISTERED_SUCCESS,
    payload: {
      user,
    },
  };
}

/**
 * @returns {{type, error: boolean}}
 */
export function isEmailRegisteredFailure() {
  return {
    type: IS_EMAIL_REGISTERED_FAILURE,
    error: true,
  };
}

/**
 * Call the api middleware and login to the website.
 *
 * @param {object} credentials the username and password credentials
 * @param {string} nextPathname the next browser location if location is successful.
 *
 * @returns {{
 *   actions: {
 *     error: loginFailure,
 *     start: loginRequest,
 *     success: loginSuccess
 *   },
 *   method: string,
 *   params: *,
 *   type,
 *   url: string
 * }}
 */
export function apiLogin(credentials, nextPathname = null) {
  return {
    actions: {
      error: loginFailure,
      start: loginRequest,
      success: data => apiLoginSuccess(data, nextPathname),
    },
    method: 'post',
    params: credentials,
    type: CALL_API,
    url: 'auth/login',
  };
}

/**
 * Call the api middleware to logout the website.
 *
 * @returns {{
 * actions: {
 *   error: logoutFailure,
 *   start: logoutRequest,
 *   success: logoutSuccess
 * },
 * method: string,
 * type,
 * url: string
 * }}
 */
export function apiLogout() {
  return {
    actions: {
      error: logoutFailure,
      start: logoutRequest,
      success: logoutThenRedirect,
    },
    method: 'post',
    type: CALL_API,
    url: 'auth/logout',
  };
}

/**
 * @param {string} email
 *
 * @returns {{
 *  actions: {
 *   error: onRegisteredErrorSetGuestEmail,
 *   start: isEmailRegisteredRequest,
 *   success: isEmailRegisteredSuccess
 *  },
 *  method: string,
 *  type,
 *  url: string
 * }}
 */
export function apiIsEmailRegistered(email) {
  return {
    actions: {
      error: () => onRegisteredErrorSetGuestEmail(email),
      start: isEmailRegisteredRequest,
      success: isEmailRegisteredSuccess,
    },
    params: { email },
    type: CALL_API,
    url: 'auth/is-registered',
  };
}

/**
 * @return {{type: string}}
 */
export function notAuthorised() {
  return {
    type: NOT_AUTHORISED,
  };
}
