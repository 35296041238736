import React from 'react';
import Grid from 'brastrap/containers/grid/GridContainer';
import Carousel from 'brastrap/containers/carousel/CarouselContainer';
import EditorialBlock from 'brastrap/editorial/editorial-block/EditorialBlock';
import type {
  Item,
  Row,
} from 'brastrap/editorial/editorial-container/editorial-container.types.js';
import * as elementTypes from 'brastrap/editorial/editorial-container/editorial-container-element-types';
import { createDataRef } from '../../utils';

const getRowItems = (row: Row, wrapInGridUnit: boolean = false) =>
  row.items &&
  row.items.map((item: Item, index) => {
    const modifiers = [...(item.modifiers || [])];

    if (item.weighting) {
      modifiers.push(`weight${String(item.weighting)}`);
    }

    const gridUnitProps: {
      className?: string,
      modifiers: Modifiers,
    } = {
      modifiers,
    };

    item.analyticsRef = createDataRef(item); // eslint-disable-line no-param-reassign

    if (item.className) {
      gridUnitProps.className = item.className;
    }

    /*
     * Much like the EditorialContainer, EditorialBlock has a list of content types (components) that it's
     * able to render. If you are not seeing what you expect, it's worth checking that
     * editorial-block-element-types.js includes your component.
     */
    const component =
      row.containerType !== 'Block' ? (
        React.createElement(elementTypes[row.containerType], item)
      ) : (
        <EditorialBlock content={item} />
      );

    const key = item.id || index;

    return wrapInGridUnit ? (
      <Grid.Unit key={key} {...gridUnitProps}>
        {component}
      </Grid.Unit>
    ) : (
      <div key={key}>{component}</div>
    );
  });

const EditorialRow = (props: Row) => {
  /*
   * Rows (Content Groups in Contentful) have the ability to hide/show
   * their content at set breakpoints
   */
  const { hideAtDesktop, hideAtMobile, hideAtTablet } = props;
  const displayProps = { hideAtMobile, hideAtTablet, hideAtDesktop };

  if (props.items) {
    const useGrid = props.hasCarousel === undefined || !props.hasCarousel;
    /*
     * Here we are going to call `getRowItems()` which will make the decision about
     * whether to render a single item in a row (for components such as Media, or Rail),
     * or use the EditorialBlock component to render multiple block-level components
     * (e.g Pod, Voice, TextBlock etc)
     */
    const items = getRowItems(props, useGrid);
    let className = 'l-grid--wraps';
    if (props.className) {
      className += ` ${props.className}`;
    }

    if (props.quarterWidth) {
      className += ` quarter-width`;
    }

    if (props.halfWidth) {
      className += ` half-width`;
    }

    if (props.threeQuarterWidth) {
      className += ` three-quarter-width`;
    }

    if (props.twoPodMobileLayout) {
      className += ` two-pod-mobile-layout`;
    }

    /*
     * Rows also have the ability to display their content
     * in a carousel. If the carousel flag is on, wrap the items here.
     */
    return props.hasCarousel ? (
      <Carousel key={props.id} guttered slidesToShow={props.slidesToShow}>
        {items}
      </Carousel>
    ) : (
      <Grid
        {...displayProps}
        className={className}
        modifiers={props.modifiers}
        key={props.id}
      >
        {items}
      </Grid>
    );
  }
  return <div />;
};

export default EditorialRow;
