/* global HTMLDivElement */
// @flow
import React, { Component } from 'react';
import { defineMessages, injectIntl, type IntlShape } from 'react-intl';
import Popover, {
  type PopoverPlacements,
} from 'brastrap/common/popover/Popover';
import { applyModifiers } from '../../utils';
import { dataLayerLocaleChange } from '../../../../shared/utils/data-layer';

export type Locale = {
  locale: string,
  path: string,
  name: string,
  flagCode: string,
};

export type Locales = Array<Locale>;

type Props = {
  locales: Locales,
  current: string,
  textVersion: boolean,
  inDrawer: boolean,
  currency: string,
  placement: PopoverPlacements,
  className: string,
  intl: IntlShape,
};

type State = {
  hasMounted: boolean,
  open: boolean,
};

const currencySymbols = {
  GBP: '£',
  USD: '$',
};

const messages = defineMessages({
  current: {
    id: 'locale-switcher.currency',
    defaultMessage: "You're shopping in {currency} {divider} ",
  },
  change: { id: 'locale-switcher.change', defaultMessage: 'Change' },
});

const STYLE_PREFIX = 'c-locale-switcher';

const Menu = ({ locales }: { locales: Locales }) => (
  <ul className="c-menu__items">
    {locales.map(({ flagCode, locale, name, path }) => (
      <li key={name} className="c-menu__item">
        <a
          className={`c-menu__label ${STYLE_PREFIX}__item`}
          href={path}
          data-locale={locale}
          onClick={() => dataLayerLocaleChange(locale && locale.toLowerCase())}
        >
          <div className={`${STYLE_PREFIX}__label`}>{name}</div>
          <div
            className={`${STYLE_PREFIX}__flag ${STYLE_PREFIX}__flag--${flagCode}`}
          />
        </a>
      </li>
    ))}
  </ul>
);

class LocaleSwitcher extends Component<Props, State> {
  container: ?HTMLDivElement;

  state = {
    hasMounted: false,
    open: false,
  };

  /**
   * The site switcher links need to be visible to Googlebot on the server.
   * The popover component by design will not render any content on the server.
   * To get around this, we render the menu items on the server, then when the component has been mounted,
   * a re-render is triggered adding the links to the popover component.
   *
   * Further reading:
   *  - https://github.com/react-bootstrap/react-bootstrap/issues/1971#issuecomment-227337729
   *  - https://github.com/airbnb/javascript/issues/684#issuecomment-264094930
   */
  componentDidMount() {
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState({ hasMounted: true });
  }

  closeMenu = () => {
    if (this.state.open) {
      this.setState({ open: false });
    }
  };

  toggleMenu = () => {
    this.setState({ open: !this.state.open });
  };

  /**
   * @return {Component}
   */
  render() {
    const {
      locales,
      current,
      intl: { formatMessage },
      placement = 'above',
      textVersion,
      inDrawer,
    } = this.props;
    const { hasMounted } = this.state;

    const modifiers = ['popover'];
    if (textVersion && !inDrawer) modifiers.push('header');
    if (inDrawer) modifiers.push('drawer');

    const menu = hasMounted ? (
      // Component is ready.
      // Ensure the menu is rendered in the popover component.
      <Popover
        onRootClick={this.closeMenu}
        placement={placement}
        show={this.state.open}
        target={this.container}
        className={applyModifiers(STYLE_PREFIX, modifiers)}
      >
        <Menu locales={locales} />
      </Popover>
    ) : (
      // Server side rendering is taking place.
      // Output the menu items so they are crawalable by googlebot.
      <div className="u-hidden">
        <Menu locales={locales} />
      </div>
    );

    return this.props.textVersion ? (
      <div
        className={`${applyModifiers(STYLE_PREFIX, ['text'])} ${
          this.props.className
        }`}
      >
        <span className={`${STYLE_PREFIX}__message`} />
        {formatMessage(messages.current, {
          currency: currencySymbols[this.props.currency],
          divider: inDrawer ? '' : '|',
        })}
        <span
          className={`${STYLE_PREFIX}--button`}
          onClick={e => {
            e.nativeEvent.stopImmediatePropagation();
            this.toggleMenu();
          }}
          ref={el => {
            this.container = el;
          }}
        >
          {formatMessage(messages.change)}
        </span>
        {menu}
      </div>
    ) : (
      <div
        className={applyModifiers(STYLE_PREFIX, ['default'])}
        onClick={e => {
          e.nativeEvent.stopImmediatePropagation();
          this.toggleMenu();
        }}
        ref={el => {
          this.container = el;
        }}
      >
        <div
          className={`${STYLE_PREFIX}__flag ${STYLE_PREFIX}__flag--main ${STYLE_PREFIX}__flag--${current}`}
        />
        {menu}
      </div>
    );
  }
}

export default injectIntl(LocaleSwitcher);
