import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import Link from 'brastrap/containers/link/LinkContainer';

import ButtonLink from 'brastrap/common/button-link/ButtonLink';
import Grid from 'brastrap/helpers/l-grid/l-grid';
import Action from 'brastrap/common/action/Action';
import { dataLayerGA4ViewCart } from '../../../../shared/utils/data-layer';

const styles = {
  popover: {
    marginTop: 2,
  },
  bag: {
    padding: 10,
  },
  buttons: {
    marginTop: 10,
  },
  dismiss: {
    float: 'right',
  },
};

const messages = defineMessages({
  checkout: { id: 'popover-bag.checkout', defaultMessage: 'Checkout' },
  viewBag: { id: 'popover-bag.view-bag', defaultMessage: 'View Bag' },
  title: { id: 'popover-bag.title', defaultMessage: 'Your Bag' },
  editBag: { id: 'popover-bag.edit', defaultMessage: 'Edit' },
  close: { id: 'message.close', defaultMessage: 'Close' },
});

const bagButtons = (formatMessage, onButtonClick) => (
  <Grid style={styles.buttons}>
    <Grid.Unit>
      <ButtonLink
        url="/bag/"
        linkType="to"
        onClick={onButtonClick}
        modifiers="view-bag block minor-filled"
        label={formatMessage(messages.viewBag)}
      />
    </Grid.Unit>
    <Grid.Unit>
      <ButtonLink
        url="/checkout/"
        linkType="to"
        onClick={onButtonClick}
        modifiers="checkout block filled primary"
        label={formatMessage(messages.checkout)}
      />
    </Grid.Unit>
  </Grid>
);

const dismiss = (onClose, label) => (
  <span style={styles.dismiss}>
    <Action
      onClick={onClose}
      modifiers={['dismiss']}
      icon={{ icon: 'dismiss', label, name: label }}
    />
  </span>
);

const PopoverBag = ({
  isEmpty,
  children,
  intl: { formatMessage },
  onButtonClick,
  onClose,
  onInteraction,
  bag,
  currency,
}) => {
  useEffect(() => {
    dataLayerGA4ViewCart(currency, bag);
  }, [currency, bag]);

  return (
    <div
      onClick={onInteraction}
      className="c-popover c-popover--bag is-visible"
      style={styles.popover}
    >
      <div style={styles.bag}>
        <h1 className="c-popover--bag-title">
          {dismiss(onClose, formatMessage(messages.close))}
          {formatMessage(messages.title)} -{' '}
          <Link href="/bag/">{formatMessage(messages.editBag)}</Link>
        </h1>
        {children}
        {isEmpty ? null : bagButtons(formatMessage, onButtonClick)}
      </div>
    </div>
  );
};

PopoverBag.propTypes = {
  children: PropTypes.node.isRequired,
  isEmpty: PropTypes.bool.isRequired,
  onInteraction: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  intl: intlShape,
  bag: PropTypes.object,
  currency: PropTypes.string,
};

PopoverBag.defaultProps = {
  isEmpty: false,
  onButtonClick: () => {},
  onClose: () => {},
  onInteraction: () => {},
};

const mapStateToProps = state => ({
  bag: state.bag,
  currency: state.app.config.currency,
});

export default injectIntl(connect(mapStateToProps)(PopoverBag));
