import { convertCustomRouteConfig } from './utils';

import App from '../containers/AppContainer';

import Account from './Account';
import AccountSignUp from './AccountSignUp';
import Auth from './Auth';
import Bag from './Bag';
import Checkout from './Checkout';
import Contact from './Contact';
import Editorial from './Editorial';
import EditorialHome from './Editorial/home';
import HelpContent from './HelpContent';
import HelpLanding from './HelpLanding';
import HelpSection from './HelpSection';
import JobList from './JobList';
import JobVacancy from './JobVacancy';
import JobVacancyMoreInfo from './JobVacancy/moreInfo';
import Magazine from './Magazine';
import OrderConfirmation from './OrderConfirmation';
import Product from './Product';
import ProductListing from './ProductListing';
import ResetPassword from './ResetPassword';
import Returns from './Returns';
import Reviews from './Reviews';
import MastectomyVatExemption from './MastectomyVatExemption';
import SavedItems from './SavedItems';
import Shop from './Shop';
import Shops from './Shops';
import ShopsLandingPage from './ShopsLandingPage';
import SizeQuizPage from './SizeQuizPage';
import Unsubscribe from './Unsubscribe';
import Voucher from './Voucher';

/**
 * Responsible for the backend and frontend
 * routing of the application.
 * (Api routes not defined here!)
 *
 * @type {{path: string, indexRoute, object, component: string, routes: *[]}}
 */
const routes = [
  {
    component: App,
    routes: [
      ...EditorialHome,
      Account,
      AccountSignUp,
      Auth,
      Bag,
      Contact,
      Checkout,
      HelpContent,
      HelpSection,
      HelpLanding, // The help routes must be in this order, or they will not be matched correctly.
      JobVacancyMoreInfo,
      JobVacancy,
      JobList, // Jobs must be in this order, or the routes will not match correctly
      Magazine,
      OrderConfirmation,
      ...Product,
      ...ProductListing,
      SavedItems,
      Shops,
      ...Shop,
      SizeQuizPage,
      Voucher,
      ...ResetPassword,
      MastectomyVatExemption,
      Unsubscribe,
      ShopsLandingPage,
      Reviews,
      Returns,
      // This entry must come last because it is a wildcard route!
      Editorial,
    ],
  },
];

/**
 * Create routes for a given path
 * @param {String} path
 * @returns {Object} containing routes.
 */
export default path => convertCustomRouteConfig(routes, path);
