/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line no-redeclare
import PropTypes from 'prop-types';

import React, { useEffect, useState } from 'react';
import {
  FormattedMessage,
  injectIntl,
  intlShape,
  defineMessages,
} from 'react-intl';
import { useCookies } from 'react-cookie';
import { ADD_TO_CART } from 'shared/constants/social-media';

import Sticker from 'brastrap/containers/sticker/StickerContainer';
import StyleColours from 'brastrap/promotional/style-colours/StyleColours';
import Sizes from 'brastrap/promotional/sizes/Sizes';
import Price from 'brastrap/promotional/price/Price';
import SaveItemButton from 'brastrap/promotional/save-item-button/SaveItemButton';
import Rating from 'brastrap/common/rating/Rating';
import AddToBagButton from 'brastrap/promotional/product-details/AddToBagButton';
import Link from 'brastrap/containers/link/LinkContainer';
import Messages from 'brastrap/containers/messages/MessagesContainer';
import Image from 'brastrap/containers/image/ImageContainer';
import Select from 'brastrap/common/field-select/FieldSelect';
import { applyModifiers } from '../../utils';
import { sendSocialMediaData } from '../../../../shared/utils/socialMedia.js';
import {
  dataLayerAddToWishList,
  dataLayerGA4SelectItem,
  dataLayerGA4SelectedRailItem,
} from '../../../../shared/utils/data-layer';

const STYLE_PREFIX = 'c-product-summary';

const messages = defineMessages({
  'one size': {
    id: 'product-summary.one-size-fits-all',
    defaultMessage: 'One size',
  },
  'select size': {
    id: 'product-summary.select-size',
    defaultMessage: 'Please select a size',
  },
});

/**
 * Filter sticker types based on priority sale -> promotion -> new
 * Bottom banners: stock status -> promotion -> online only
 * @param {Object} attributes
 * @returns {Array}
 */

const getFilteredStickerTypesFromAttributes = attributes => {
  let filteredKeys = [];
  const allKeys = Object.keys(attributes);
  const roundals = [];
  const banners = [];
  const stockStatuses = /low-stock|awaiting-stock|sizeOutOfStock|colourOutOfStock/gi;
  const availableBanners = ['promotionBanner'];
  allKeys.forEach(key => {
    if (stockStatuses.test(key)) {
      // Overwrite any banner stickers with stock status banner sticker
      filteredKeys = [key];
    } else if (availableBanners.includes(key) && !filteredKeys.length) {
      banners.push(key);
    } else if (!availableBanners.includes(key)) {
      roundals.push(key);
    }
  });

  // sort so that banners and roundals each get ordered and if we fetch the last element
  // then it will return the available sticker based on priority
  if (banners.length && !filteredKeys.length) {
    filteredKeys.push(banners.sort().pop());
  }

  if (roundals.length) {
    filteredKeys.push(roundals.sort().pop());
  }
  return filteredKeys;
};

/**
 * Generate all the stickers from the attributes
 * As we can currently only have one round sticker type. Prioritise promotion stickers over new ones.
 *
 * @param {Object} attributes
 * @param {string} currency
 * @returns {Array}
 */
export const getStickersFromAttributes = (attributes, currency) =>
  getFilteredStickerTypesFromAttributes(attributes).map((property, index) => {
    const stickerProps = {
      ...attributes[property],
      currency,
    };
    return <Sticker key={index} {...stickerProps} />;
  });

/**
 * @param {number} number
 * @returns {boolean}
 */
const isNumeric = number => !isNaN(parseFloat(number)) && isFinite(number);

/**
 * Returns the class name based on the modifiers and styleColourCode
 *
 * @param {Boolean} isFetching
 * @param {Array} modifiers
 * @param {string} styleColourCode
 * @returns {string}
 */
const getClassName = (isFetching, modifiers, styleColourCode) => {
  const allModifiers = [...modifiers, styleColourCode];

  return applyModifiers(STYLE_PREFIX, allModifiers);
};

const ProductSummary = props => {
  const {
    currency,
    colours,
    defaultStyleColourId,
    disableAdd,
    disableRating,
    disableLink,
    displaySwatches,
    intl: { formatMessage },
    isAccessoryContainer,
    isFetching,
    displaySaveItemButton,
    modifiers,
    onAddToBag,
    onStyleColourClick,
    onStyleColourChange,
    onSaveItemPress,
    savedItemsMap,
    onSizeSelect,
    position,
    rating,
    styleCode,
    styleName,
    metadata,
    locationAttribute,
    dataRef,
    socialMediaChannels,
    customerDetails,
    isForHomepageOnly,
    hideSizeRange,
    showSwatchesAsDropDown,
    isDesktop,
    isForPdp,
    id,
    collection,
    pathname,
  } = props;

  const [selectedStyleColourValue, setSelectedStyleColourValue] = useState(
    null
  );
  const [showBackShot, setShowBackShot] = useState(false);
  const [thumbnailHeight, setThumbnailHeight] = useState(null);
  const [elWithinView, setElWithinView] = useState(false);
  const [showBackShotHint, setShowBackShotHint] = useState(false);

  const onScroll = () => {
    if (
      isElementInViewport() &&
      id === 'product-accessory-0' &&
      !elWithinView &&
      !showBackShot
    ) {
      setElWithinView(true);
    } else {
      setElWithinView(false);
    }
  };

  useEffect(() => {
    if (elWithinView) {
      setShowBackShotHint(true);
      setTimeout(() => {
        setShowBackShotHint(false);
      }, [1500]);
    } else {
      setShowBackShotHint(false);
    }
  }, [elWithinView]);

  useEffect(() => {
    if (window !== undefined) {
      window.addEventListener('scroll', onScroll);
    }
  }, []);

  useEffect(() => {
    if (isForPdp && isDesktop) {
      setThumbnailHeight(605);
    } else if (isForPdp && !isDesktop) {
      setThumbnailHeight(550);
    } else {
      setThumbnailHeight(704);
    }
  }, [isForPdp, isDesktop]);

  const THUMBNAIL_SIZE = {
    height: thumbnailHeight,
    width: 550,
  };

  const [cookies] = useCookies(['']);

  /** *
   * Function to check if first accessory is in view port
   * @returns {boolean}
   ** */
  function isElementInViewport() {
    let isWithinView = false;

    if (document !== undefined && window !== undefined) {
      const el = document.getElementById('product-accessory-0');

      if (el) {
        const rect = el.getBoundingClientRect();
        isWithinView =
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth);
      }
    }
    return isWithinView;
  }

  const selectedStyleColour =
    colours.find(styleColour => styleColour.selected) || colours[0];

  if (!selectedStyleColour) {
    return null;
  }

  let productSummaryActionsNode = null;

  let classNames = 'c-product-summary__container u-block-link';

  if (dataRef) {
    classNames = classNames.concat(' googleAnalyticsTracking');
  }

  const aProps = {
    className: classNames,
    onClick: e => {
      // For accessories: if the user clicks on the size picker, the 'add to bag' button, or anywhere in the size picker
      // modal, then prevent the click event from bubbling up to the wrapping anchor tag and triggering a redirect.
      // Also ensure that clicking on a swatch does not cause the user to be routed a different page
      if (
        (productSummaryActionsNode &&
          productSummaryActionsNode.contains(e.target)) ||
        e.target.className.includes('c-swatch')
      ) {
        e.preventDefault();
      }
      onStyleColourClick(styleCode, selectedStyleColour);
      dataLayerGA4SelectItem(
        styleCode,
        styleName,
        collection,
        selectedStyleColour,
        pathname
      );
      if (isForHomepageOnly) {
        dataLayerGA4SelectedRailItem(styleName, styleCode);
      }
    },
    'data-ref': isForHomepageOnly ? `Rail Item: ${styleName}` : null,
  };

  const actionsProps = {
    className: 'c-product-summary__actions',
    // If the product is clickable, make sure we have a reference the DOM node so we can prevent bubbling
    ...(!disableLink && {
      ref: element => {
        productSummaryActionsNode = element;
      },
    }),
  };

  const messageTarget = `productSummary-${position}`;

  if (disableLink) {
    delete aProps.onClick;
  } else {
    aProps[locationAttribute] = selectedStyleColour.url;
  }

  const socialMediaData = {
    customerDetails: customerDetails,
    product: {
      category: selectedStyleColour.category,
      styleColourCode: selectedStyleColour.styleColourCode,
      styleColourName: selectedStyleColour.styleColourName,
      prices: selectedStyleColour.pricing,
    },
    quantity: 1,
    currency: currency,
    fbp: cookies._fbp,
    fbc: cookies._fbc,
  };

  return (
    <section
      id={id}
      className={getClassName(
        isFetching,
        modifiers,
        selectedStyleColour.styleColourCode
      )}
    >
      <Link {...aProps}>
        {/* TODO: Move swatches out of the media div. The swatches don't do anything
          in accessories at present and, if this remains unchanged, it might be possible
          to hide them, and revert this component to how it was initially set up */}
        <div
          onMouseEnter={() => disableLink && setShowBackShot(true)}
          onMouseLeave={() => disableLink && setShowBackShot(false)}
          onTouchStart={() => disableLink && setShowBackShot(x => !x)}
          className={`c-product-summary__media ${
            isForPdp && 'c-product-summary__media--pdp-accesories'
          }`}
        >
          <div className="c-product-summary__img-container">
            {(showBackShot || showBackShotHint) &&
            selectedStyleColour.images.backShotImage.alt !==
              'Awaiting photo' ? (
              <Image
                className="c-product-summary__img"
                src={
                  selectedStyleColour.images.backShotImage &&
                  selectedStyleColour.images.backShotImage.src
                }
                alt={
                  selectedStyleColour.images.backShotImage &&
                  selectedStyleColour.images.backShotImage.alt
                }
                height={THUMBNAIL_SIZE.height}
                width={THUMBNAIL_SIZE.width}
              />
            ) : (
              <Image
                className="c-product-summary__img"
                src={
                  selectedStyleColour.images.listing &&
                  selectedStyleColour.images.listing.src
                }
                alt={
                  selectedStyleColour.images.listing &&
                  selectedStyleColour.images.listing.alt
                }
                height={THUMBNAIL_SIZE.height}
                width={THUMBNAIL_SIZE.width}
              />
            )}
            {getStickersFromAttributes(
              selectedStyleColour.attributes,
              currency
            )}
          </div>
          {!showSwatchesAsDropDown && displaySwatches && !!colours.length && (
            <div className="c-product-summary__style-colours">
              <StyleColours
                defaultStyleColourId={defaultStyleColourId}
                styleCode={styleCode}
                list={colours}
                onSelect={colour =>
                  onStyleColourChange(
                    colour.styleColourCode,
                    defaultStyleColourId
                  )
                }
              />
            </div>
          )}
        </div>

        <div className="c-product-summary__main">
          <div className="c-product-summary__productInfoContainer">
            <div className="c-product-summary__title">
              {!isForHomepageOnly && (
                <small className="c-product-summary__code">{styleCode}</small>
              )}
              <h3 className="c-product-summary__name">{styleName}</h3>
            </div>

            {
              // Temporarily disabling the accessories star rating - see gitlab #536
              (disableRating
                ? false
                : rating &&
                  isForHomepageOnly &&
                  isNumeric(rating.total) &&
                  isNumeric(rating.value)) && (
                <div>
                  <dt className="u-hidden">
                    <FormattedMessage
                      id="product-summary.rating"
                      defaultMessage="Product rating:"
                    />
                  </dt>
                  <dd className="c-product-summary__rating">
                    <Rating {...rating} />
                  </dd>
                </div>
              )
            }

            <dl className="c-product-summary__meta">
              <dt className="u-hidden">
                <FormattedMessage
                  id="product-summary.price"
                  defaultMessage="Price:"
                />
              </dt>

              <dd className="c-product-summary__price">
                <Price currency={currency} {...selectedStyleColour.pricing} />
              </dd>

              <dt className="u-hidden">
                <FormattedMessage
                  id="product-summary.size-range"
                  defaultMessage="Available sizes:"
                />
              </dt>

              {!hideSizeRange && (
                <dd className="c-product-summary__sizes">
                  {selectedStyleColour.sizeRange}
                </dd>
              )}

              {
                // Temporarily disabling the accessories star rating - see gitlab #536
                (disableRating
                  ? false
                  : rating &&
                    !isForHomepageOnly &&
                    isNumeric(rating.total) &&
                    isNumeric(rating.value)) && (
                  <div>
                    <dt className="u-hidden">
                      <FormattedMessage
                        id="product-summary.rating"
                        defaultMessage="Product rating:"
                      />
                    </dt>
                    <dd className="c-product-summary__rating">
                      <Rating {...rating} />
                    </dd>
                  </div>
                )
              }
            </dl>
          </div>

          {onAddToBag && (
            <div {...actionsProps}>
              {showSwatchesAsDropDown && (
                <fieldset id="colour-selector-dropdown" className="c-sizes">
                  <legend className="c-sizes__header">
                    <span className="c-sizes__title u-hidden">
                      <FormattedMessage
                        id="sizes.title"
                        defaultMessage="Choose a size"
                      />
                    </span>
                  </legend>
                  <Select
                    options={colours.map(colour => ({
                      label: colour.name.toUpperCase(),
                      value: colour.styleColourCode,
                      disabled: false,
                    }))}
                    onChange={e => {
                      onStyleColourChange(
                        e.currentTarget.value,
                        defaultStyleColourId
                      );
                      setSelectedStyleColourValue(
                        colours.find(
                          colour => colour.styleColourCode === e.target.value
                        ).styleColourCode
                      );
                    }}
                    value={
                      selectedStyleColourValue ||
                      colours.find(colour => colour.id === defaultStyleColourId)
                        .styleColourCode
                    }
                  />
                </fieldset>
              )}

              {selectedStyleColour.sizes.variants.length &&
              selectedStyleColour.sizes.variants[0].code === 'ONE' ? (
                <fieldset className="c-sizes">
                  <p className="c-product-summary__optionTitle">
                    {formatMessage(messages['one size'])}
                  </p>
                </fieldset>
              ) : (
                <Sizes
                  onChange={sku =>
                    onSizeSelect(styleCode, sku, defaultStyleColourId)
                  }
                  sizes={selectedStyleColour.sizes}
                  {...props.sizePicker}
                  isAccessoryContainer={isAccessoryContainer}
                  useSingleDropdown
                />
              )}

              <Messages target={messageTarget} />
              <AddToBagButton
                isAdded={props.isAdded}
                isFetching={props.isFetching}
                onClick={() => {
                  if (props.sizePicker.value) {
                    sendSocialMediaData(
                      ADD_TO_CART,
                      socialMediaChannels,
                      socialMediaData
                    );
                    onAddToBag(styleCode, defaultStyleColourId, null);
                  } else {
                    onAddToBag(styleCode, defaultStyleColourId, {
                      message: formatMessage(messages['select size']),
                      target: messageTarget,
                    });
                  }
                }}
                modifiers={
                  disableAdd
                    ? ['disabled', 'add-to-bag', 'major']
                    : ['add-to-bag', 'major']
                }
              />
            </div>
          )}
        </div>
      </Link>
      {displaySaveItemButton && (
        <SaveItemButton
          onClick={() => {
            dataLayerAddToWishList(currency, {
              prices: {
                price: selectedStyleColour.pricing.price,
                originalPrice: selectedStyleColour.pricing.originalPrice,
              },
              styleCode: styleCode,
              styleColourCode: selectedStyleColour.styleColourCode,
              styleName: styleName,
              colour: {
                colour: selectedStyleColour.name,
              },
              brand: null,
              category: selectedStyleColour.category,
            });
            onSaveItemPress({
              styleCode,
              styleColourCode: selectedStyleColour.styleColourCode,
            });
          }}
          isItemSaved={
            savedItemsMap[styleCode] ||
            savedItemsMap[selectedStyleColour.styleColourCode]
          }
        />
      )}
      {metadata && (
        <div className="c-product-summary__metadata">{metadata}</div>
      )}
    </section>
  );
};

ProductSummary.propTypes = {
  colours: PropTypes.arrayOf(
    PropTypes.shape({
      attributes: PropTypes.object,
      name: PropTypes.string,
      pricing: PropTypes.object,
      selected: PropTypes.bool,
      sizeRange: PropTypes.string.isRequired,
      swatch: PropTypes.string,
      images: PropTypes.shape({
        listing: PropTypes.shape({
          alt: PropTypes.string,
          src: PropTypes.string,
        }),
      }),
      // Sizes should be object.
      sizes: PropTypes.shape({
        value: PropTypes.string,
        variants: PropTypes.arrayOf(
          PropTypes.shape({
            available: PropTypes.bool,
            code: PropTypes.string,
            label: PropTypes.string,
            skuCode: PropTypes.string,
            status: PropTypes.string,
            stockStatus: PropTypes.string,
            value: PropTypes.string,
          })
        ),
      }),
    })
  ),
  currency: PropTypes.string,
  defaultStyleColourId: PropTypes.number,
  disableAdd: PropTypes.bool,
  disableLink: PropTypes.bool,
  disableRating: PropTypes.bool,
  displaySwatches: PropTypes.bool,
  displaySavedSize: PropTypes.bool,
  intl: intlShape,
  isFetching: PropTypes.bool,
  isAdded: PropTypes.bool,
  modifiers: PropTypes.array,
  position: PropTypes.number,
  styleName: PropTypes.string.isRequired,
  sizePicker: PropTypes.object,
  onAddToBag: PropTypes.func,
  onStyleColourClick: PropTypes.func,
  onStyleColourChange: PropTypes.func.isRequired,
  onSizeSelect: PropTypes.func,
  onSaveItemPress: PropTypes.func,
  rating: PropTypes.shape(Rating.propTypes),
  styleCode: PropTypes.string,
  isAccessoryContainer: PropTypes.bool,
  savedItemsMap: PropTypes.object,
  isLoggedIn: PropTypes.bool,
  displaySaveItemButton: PropTypes.bool,
  metadata: PropTypes.string,
  locationAttribute: PropTypes.string,
  dataRef: PropTypes.string,
  tiktokPixelId: PropTypes.string,
  customerDetails: PropTypes.object,
  socialMediaChannels: PropTypes.array,
  isForHomepageOnly: PropTypes.bool,
  hideSizeRange: PropTypes.bool,
  showSwatchesAsDropDown: PropTypes.bool,
  isDesktop: PropTypes.bool,
  isForPdp: PropTypes.bool,
  id: PropTypes.string,
  collection: PropTypes.string,
  pathname: PropTypes.string,
};

ProductSummary.defaultProps = {
  colours: [],
  currency: 'GBP',
  disableLink: false,
  displaySwatches: true,
  displaySavedSize: false,
  isLoggedIn: false,
  displaySaveItemButton: true,
  modifiers: [],
  savedItemsMap: {},
  locationAttribute: 'href',
  onStyleColourChange: () => {},
  onStyleColourClick: () => {},
  onSaveItemPress: () => {},
};

export default injectIntl(ProductSummary);
