/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable no-unused-expressions */
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { defineMessages, injectIntl, FormattedMessage } from 'react-intl';
import Swatch from 'brastrap/promotional/swatch/Swatch';
import Link from 'brastrap/containers/link/LinkContainer';
import {
  COLOUR,
  dataLayerSelectItemOption,
  setDataLayer,
} from '../../../../shared/utils/data-layer';

const STYLE_PREFIX = 'c-style-colours';

defineMessages({
  swatchSale: { id: 'style-colours.swatch-sale', defaultMessage: 'Sale' },
});

const StyleColours = props => {
  const [coloursNotInStockList, setColoursNotInStockList] = useState(null);
  const [swatchLimit, setSwatchLimit] = useState(
    props.list && props.list.length
  );
  const [showRemainingSwatchesText, setShowRemainingSwatchesText] = useState(
    null
  );
  const [remainingSwatches, setRemainingSwatches] = useState(null);

  const { locationAttribute, isMobile, coloursNotInStock } = props;

  let className = STYLE_PREFIX;

  props.modifiers.forEach(modifier => {
    className += ` ${STYLE_PREFIX}--${modifier}`;
  });

  useEffect(() => {
    setColoursNotInStockList(null);

    if (
      coloursNotInStock &&
      coloursNotInStock !== '' &&
      coloursNotInStock.length > 0
    ) {
      setColoursNotInStockList(coloursNotInStock);
    }
  }, [coloursNotInStock]);

  useEffect(() => {
    if (props.modifiers && !props.modifiers.includes('large') && props.list) {
      setSwatchLimit(5);
    }
  }, [props.modifiers]);

  useEffect(() => {
    const remainder = props.list.length - swatchLimit;
    if (remainder > 0) {
      setShowRemainingSwatchesText(true);
      setRemainingSwatches(remainder);
    }
  }, [swatchLimit]);

  return (
    <fieldset className={className}>
      <legend className="c-style-colours__header">
        <span className="c-style-colours__title u-hidden">
          <FormattedMessage
            id="style-colours-title"
            defaultMessage="Choose a colour"
          />
        </span>
      </legend>
      <div className="c-style-colours__items">
        {props.list.slice(0, swatchLimit).map((item, key) => {
          let styleColourClassName = isMobile
            ? 'c-style-colour__mobile'
            : 'c-style-colour';
          const inputProps = {
            'data-style-colour': item.styleColourCode,
            name: props.defaultStyleColourId
              ? `${props.styleCode}--${props.defaultStyleColourId}`
              : props.styleCode,
            id: `${item.styleColourCode}--${props.styleCode}`,
            value: item.swatch,
            checked: !!item.selected,
            onChange: () => {}, // To satisfy React which expects onChange when checked is set
          };

          if (item.selected) {
            styleColourClassName += ' c-style-colour--selected';
          }

          const isUnavailable = coloursNotInStockList?.includes(
            item.styleColourId
          );
          if (isUnavailable) {
            styleColourClassName += ' c-style-colour--unavailable';
          } else {
            styleColourClassName.replace(' c-style-colour--unavailable', '');
          }

          const swatch = (isSmallSwatch = false) => (
            <Swatch
              colour={item.swatch}
              swatchPatternImage={
                isSmallSwatch ? null : item.swatchPatternImage
              }
              isUnavailable={isUnavailable}
            />
          );
          const input = (
            <input
              className="c-style-colour__check u-hidden"
              type="radio"
              {...inputProps}
            />
          );
          const sale = item.onSale ? (
            <span className="c-style-colour__sale">
              <FormattedMessage
                id="style-colours.swatch-sale"
                defaultMessage="Sale"
              />
            </span>
          ) : null;
          const labelProps = {
            className: 'c-style-colour__label',
            htmlFor: inputProps.id,
            title: item.name,
          };
          const span = (
            <span className="c-style-colour__name">{item.name}</span>
          );
          let aProps;
          let label;
          if (!props.onSelect && !!item.url) {
            aProps = {
              key,
              [locationAttribute]: item.url,
              id: `${item.styleColourCode}--${props.styleCode}`,
              className: styleColourClassName,
              onClick: () => {
                if (typeof window !== 'undefined' && props.product) {
                  dataLayerSelectItemOption(
                    COLOUR,
                    item.name.toLowerCase(),
                    props.product
                  );
                  props.selectedSku &&
                    setDataLayer({
                      event: 'styleColourChange',
                      selectedSkuCode: `${item.styleColourCode}${props.selectedSku.code}`,
                    });
                }
              },
            };
          }

          if (aProps) {
            if (isMobile) {
              label = <label {...labelProps}>{swatch()}</label>;
            } else {
              label = (
                <label {...labelProps}>
                  {swatch()}
                  {span}
                </label>
              );
            }
            return (
              <Link {...aProps}>
                {sale}
                {label}
              </Link>
            );
          }

          label = (
            <label
              {...labelProps}
              onClick={e => {
                e.preventDefault();
                props.onSelect(item, key);
              }}
            >
              {swatch(true)}
            </label>
          );

          return (
            <div key={key} className="c-style-colour">
              {input}
              {label}
            </div>
          );
        })}
        <div className="c-style-colour__showRemainingSwatches">
          {showRemainingSwatchesText && `+ ${remainingSwatches}`}
        </div>
      </div>
    </fieldset>
  );
};

StyleColours.propTypes = {
  defaultStyleColourId: PropTypes.number,
  styleCode: PropTypes.string.isRequired,
  modifiers: PropTypes.array,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      styleColourCode: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      swatch: PropTypes.string.isRequired,
      selected: PropTypes.bool,
      url: PropTypes.string,
      onSale: PropTypes.bool,
    })
  ),
  onSelect: PropTypes.func,
  locationAttribute: PropTypes.string,
  isMobile: PropTypes.bool,
  coloursNotInStock: PropTypes.array,
  selectedSku: PropTypes.object,
  product: PropTypes.object,
};

StyleColours.defaultProps = {
  modifiers: [],
  list: [],
  locationAttribute: 'href',
};

export default injectIntl(StyleColours);
