import generateAsyncRouteComponent from '../generateAsyncRouteComponent';

export const VACANCY_PATH_ROOT = 'careers-at-bravissimo/vacancies/';

export default {
  path: `${VACANCY_PATH_ROOT}:vacancy/`,
  component: generateAsyncRouteComponent({
    loader: () => import('./containers/VacancyContainer'),
  }),
};
