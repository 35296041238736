/* eslint no-underscore-dangle: 0 */

import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import Tag from 'brastrap/common/tag/Tag';
import Link from 'brastrap/containers/link/LinkContainer';
import { createDataRef } from '../../utils';

type Props = {
  items: Array<{
    isVisible: boolean,
    label: string,
    showTag: boolean,
    url: string,
    hasLumieraStyling: boolean,
    hasMarshmallowStyling: boolean,
    hasBoldStyling: boolean,
    columns: Array<>
  }>,
  onNavigationRequest: () => void,
  isDesktop: boolean,
  locale: string
};

const messages = defineMessages({
  title: { id: 'navigation.title', defaultMessage: 'Shop online' },
});

const Navigation = (props: Props) => {
  const [lastPartOfCurrentUrl, setLastPartOfCurrentUrl] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const STYLE_PREFIX = 'c-navigation';

  useEffect(() => {
    setLastPartOfCurrentUrl(getLastPartOfUrl(window && window.location.href));

    if (typeof window !== 'undefined') {
      if (getLastSelectedItemId()) {
        const amountOfMatchingItems = getMatchingItemIds().length > 0 && getMatchingItemIds().filter(id => id === getLastSelectedItemId()).length;
        if (amountOfMatchingItems === 1) {
          setSelectedItemId(getLastSelectedItemId());
        } else if (amountOfMatchingItems > 1) {
          setSelectedItemId(getMatchingItemIds()[0]);
        } else {
          setSelectedItemId(getLastSelectedItemId());
        }
        localStorage.removeItem('LastSelectedItemId');
      } else {
        props.items.forEach(item => {
          if (item.columns && item.columns.length > 0) {
            item.columns
              .filter(column => column._type === 'menuColumnLinks')
              .forEach(menuColumnLink => menuColumnLink.linkGroups
                .forEach(linkGroup => linkGroup.links
                  .forEach(link => {
                    if (getLastPartOfUrl(window && window.location.href) === getLastPartOfUrl(link.url)) {
                      setSelectedItemId(item.id);
                    }
                  })));

            item.columns
              .filter(column => column._type === 'menuColumnCollections')
              .forEach(menuColumnCollection => menuColumnCollection.collections
                .forEach(collection => {
                  if (getLastPartOfUrl(window && window.location.href) === getLastPartOfUrl(collection.url)) {
                    setSelectedItemId(item.id);
                  }
                }));
          }

          if (getLastPartOfUrl(window && window.location.href) === getLastPartOfUrl(item.url)) {
            setSelectedItemId(item.id);
          }
        });
      }
    }
  }, []);

  const buildAnchorProps = (item, key) => {
    const aProps = {
      href: item.url,
      title: item.label,
      id: item.id,
      analyticsRef: createDataRef({ _type: 'navigation', name: item.label }),
    };

    if (item.isMegaMenu) {
      aProps.onClick = e => {
        e.preventDefault();
        props.onNavigationRequest(item, key, props.isDesktop);
      };
      aProps['aria-haspopup'] = true;
      aProps['aria-controls'] = `${item.id}-menu`;
    }
    return aProps;
  };

  /**
 * Get matching item ids set in local storage
 *
 * @returns {String}
 */
  function getMatchingItemIds() {
    return JSON.parse(localStorage.getItem('MatchingItemIds') || "[]");
  }

  /**
 * Sets any matching item ids as a strigified array in local storage
 *
 * @param {Array} ids
 */
  function setMatchingItemIds(ids) {
    localStorage.setItem('MatchingItemIds', JSON.stringify(ids));
  }

  /**
 * Gets the last selected item id from local storage
 *
 * @returns {String}
 */
  function getLastSelectedItemId() {
    return localStorage.getItem('LastSelectedItemId');
  }

  /**
 * Sets the last selected item id in local storage
 *
 * @param {String} id
 */
  function setLastSelectedItemId(id) {
    localStorage.setItem('LastSelectedItemId', id);
  }

  /**
 * Gets the last part of url when seperated by forward slashes
 *
 * @param {String} url
 * @returns {String}
 */
  function getLastPartOfUrl(url) {
    return url.split('/').filter(e => e).slice(-1)[0];
  }

  /**
 * Checks if current item matches any items in the nav/mega menu item lists and sets them accordingly
 *
 * @param {Object} item
 */
  async function checkIfCurrentUrlMatchesMenuItem(item) {
    if (typeof window !== 'undefined') {
      setLastSelectedItemId(item.id);
    }

    if (item.columns.length > 0) {
      item.columns
        .filter(column => column._type === 'menuColumnLinks')
        .forEach(menuColumnLink => menuColumnLink.linkGroups
          .forEach(linkGroup => linkGroup.links
            .forEach(link => {
              if (lastPartOfCurrentUrl === getLastPartOfUrl(link.url) || item.id === getLastSelectedItemId()) {
                const copyOfMatchingItemIds = getMatchingItemIds();
                // eslint-disable-next-line no-unused-expressions
                copyOfMatchingItemIds.length > 0 ? setMatchingItemIds(copyOfMatchingItemIds.push(item.id)) : setMatchingItemIds([item.id]);
              }
            })));

     item.columns
        .filter(column => column._type === 'menuColumnCollections')
        .forEach(menuColumnCollection => menuColumnCollection.collections
          .forEach(collection => {
            if (lastPartOfCurrentUrl === getLastPartOfUrl(collection.url) || item.id === getLastSelectedItemId()) {
              const copyOfMatchingItemIds = getMatchingItemIds();
              // eslint-disable-next-line no-unused-expressions
              copyOfMatchingItemIds.length > 0 ? setMatchingItemIds(copyOfMatchingItemIds.push(item.id)) : setMatchingItemIds([item.id]);
            }
          }));
    } else {
      setMatchingItemIds([]);
      const copyOfMatchingItemIds = getMatchingItemIds();
      copyOfMatchingItemIds.push(item.id);
      setMatchingItemIds(copyOfMatchingItemIds);
    }
  }

  return (
    <nav className={STYLE_PREFIX} aria-labelledby="products-nav">
      <div className={`${STYLE_PREFIX}__body ${props.locale.selected === 'en-US' ? `${STYLE_PREFIX}__body--usOnly` : ''}`}>
        <h1 className={`${STYLE_PREFIX}__title u-hidden`} id="products-nav">
          <FormattedMessage {...messages.title} />
        </h1>

        {props.isDesktop && (
          <ul className={`${STYLE_PREFIX}__items ${props.locale.selected === 'en-US' ? `${STYLE_PREFIX}__items--usOnly` : ''}`}>
            {props.items.map((item, key) => (
              <li
                onClick={() => { checkIfCurrentUrlMatchesMenuItem(item); localStorage.setItem('SelectedNavItem', item.label);}}
                key={key}
                className={`${STYLE_PREFIX}__item${item.isVisible ? ` ${STYLE_PREFIX}__item--active` : ''} ${item.disabledMenuItem ? `${STYLE_PREFIX}__item--disabled` : ''} ${props.locale.selected === 'en-US' ? `${STYLE_PREFIX}__item--usOnly` : ''} ${STYLE_PREFIX}__item${item.isVisible && props.locale.selected === 'en-US'? ` ${STYLE_PREFIX}__item--usOnly--active` : ''} ${props.locale.selected === 'en-US' && selectedItemId === item.id ? `${STYLE_PREFIX}__item--highlightedNavItem` : ''} ${item.hasLumieraStyling ? `${STYLE_PREFIX}__item--hasLumieraStyling` : ''} ${item.hasMarshmallowStyling ? `${STYLE_PREFIX}__item--hasMarshmallowStyling` : ''} ${item.hasBoldStyling ? `${STYLE_PREFIX}__item--hasBoldStyling` : ''}`}
              >
                <Link {...buildAnchorProps(item, key)} role="menuitem">
                  {item.showTag ? <Tag label={item.label} /> : item.label}
                </Link>
              </li>
            ))}
          </ul>
        )}
      </div>
    </nav>
  );
};

Navigation.defaultProps = {
  items: [],
  onNavigationRequest: () => { },
};

export default Navigation;
